@import url("https://rsms.me/inter/inter.css");

:root {
  --main-dark-color: #0e1111;
  --main-light-color: #fbfbf8;
  --main-accent-color: #ff0108;
}

a{
  color: var(--main-light-color);
}

.app-container{
  display: flex;
  width: 300px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
}

.form{
  font-family: "Inconsolata", monospace;
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 25px 0;
}

.field{
  font-size: 24px;
  font-weight: 700;
  padding: 15px;
  transition: border .2s ease;
  border: 1px solid;
  color: var(--main-light-color);
  border-color: var(--main-accent-color);
  border-radius: 8px;
  background: var(--main-dark-color);
  margin-bottom: 25px;
}

.result-container{
  margin-top: 25px;
}

input, button {
  border:none;
  text-transform: uppercase;
  font-family: 'Roboto Mono', monospace;
}

button{
  font-size: 16px;
  font-weight: 700;
  padding: 16px 32px;
  cursor: pointer;
  width: 100%;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

#submitbutton{
  border: 1px solid var(--main-light-color);
  border-radius: 8px;
  background: var(--main-light-color);
}

#clearbutton{
  border: 1px solid var(--main-light-color);
  border-radius: 8px;
  color: var(--main-light-color);
  background: var(--main-dark-color);
}

input:focus, button:focus {
  outline:0;
}

.row{
  font-size: 1.5rem;
  margin-bottom: 50px;
  max-width: 400px;
}

.result-metadata{
  font-family: "Inconsolata", monospace;
  font-size: 1rem;
  margin-bottom: 50px;
}

.launch{
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0 10px;
  align-content: center;
  padding-bottom: 50px;
  position: relative;
}

.launch::after{
  content: "";
  height: 1px;
  width: 100%;
  background-color: var(--main-light-color);
  position: absolute;
  bottom: 0;
}

.launch-video{
  height: 75px;
  width: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--main-accent-color);
  text-decoration: none;
  text-transform: uppercase;
  font-size: .75rem;
}

.launch-video img{
  height: 25px;
  margin-bottom: 10px
}

@media only screen and (min-width: 450px){
  .app-container{
    width:auto;
  }
  .form{
    width: 400px;
    margin: 50px 0;
  }
}